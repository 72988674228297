import { EventsUtilsService } from '../../../shared/services/events-utils.service';
import { RescheduleDayDialogComponent } from './../../../shared/components/reschedule-day-dialog/reschedule-day-dialog.component';
import { ScheduleResponse } from './../../../shared/models/schedule/schedule-response.model';
import { TrainingAuditService } from './../../../core/services/training-audit.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewMapComponent } from '../../../shared/components/view-map/view-map.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MyEvents } from '../../../shared/models/my-events/my-events.model';
import { MyEventsService } from '../../../core/services/my-events.service';
import { TerritoryService } from '../../../core/services/territory-management.service';
import { MyEventsArray } from '../../../shared/models/my-events/my-events-array.model';
import { MyEventType } from '../../../shared/enum/my-event-type.enum';
import { MyEventsFilter } from '../../../shared/models/my-events/my-events-filter.model';
import { UtilsService } from '../../../core/services/utils.service';
import { DatePipe } from '@angular/common';
import { MyEventsDetails } from '../../../shared/models/my-events/my-events-details.model';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { SCHEDULE_TYPE } from '../../../shared/constants/schedule.constants';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { LoginService } from '../../../core/services/login.service';
import { CountryGlobalVariables } from '../../../shared/constants/country-global-variables';
import { ConfirmDialogComponent } from './../../../shared/components/confirm-dialog/confirm-dialog.component';


export const MY_FORMATS = {
	parse: {
		dateInput: CountryGlobalVariables.US.dateFormat
	},
	display: {
		dateInput: CountryGlobalVariables.US.dateFormat,
		monthYearLabel: 'YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'YYYY'
	}
};

@Component({
	selector: 'app-my-events',
	templateUrl: './my-events.component.html',
	styleUrls: ['./my-events.component.scss'],
	providers: [
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
	]
})
export class MyEventsComponent implements OnInit {

	scheduled = true;
	range = new UntypedFormGroup({
		start: new UntypedFormControl(null),
		end: new UntypedFormControl(null),
	});

	min = new Date();
	max = new Date();

	data: MyEvents[] = [];
	allUnscheduledData: MyEventsDetails[] = [];
	displayUnscheduledBadge = false;

	task: any = {
		name: 'All',
		checked: false,
		subtasks: [
			{
				id: MyEventType.TRAINING,
				name: this.loginSvc.isUkUser() ? CountryGlobalVariables.UK.trainingCalls : CountryGlobalVariables.US.trainingCalls,
				checked: false
			},
			{ id: MyEventType.AUDIT, name: 'Audits', checked: false },
			{ id: MyEventType.APPOINTMENT, name: 'Appointments', checked: false },
		],
	};
	allComplete = false;

	// error
	messageList = [];
	showNotification = false;

	noRecords = false;
	ownedByMeCtrl: UntypedFormControl = new UntypedFormControl(true);

	constructor(private dialog: MatDialog,
		private fb: UntypedFormBuilder,
		private datePipe: DatePipe,
		private territorySvc: TerritoryService,
		private myEventsSvc: MyEventsService,
		private terrService: TerritoryService,
		private loginSvc: LoginService,
		private msgBanner: MsgBannerService,
		private trainingAuditService: TrainingAuditService,
		public eventsUtils: EventsUtilsService) {
		this.min.setDate(this.min.getDate() - 7);
		this.max.setDate(this.max.getDate() + 30);
		this.initRange();

		if (this.loginSvc.isUkUser()) {
			MY_FORMATS.display.dateInput = CountryGlobalVariables.UK.dateFormat;
			MY_FORMATS.parse.dateInput = CountryGlobalVariables.UK.dateFormat;
		}
	}

	ngOnInit(): void {
		if (history.state.data && history.state.data.scheduleType === SCHEDULE_TYPE.UNSCHEDULED) {
			this.scheduled = false;
		}

		this.loadData();
		this.ownedByMeCtrl.valueChanges.subscribe(v => {
			this.displayOwnedByRecords(v);
		});
	}

	get formOwnedBy() {
		return this.ownedByMeCtrl;
	}

	loadData() {
		this.showNotification = false;

		this.getUnscheduledCount();
		this.updateValidators();

		if (this.scheduled) {
			this.getDataForRange();
		} else {
			this.getUnscheduled();
		}
	}

	updateValidators() {
		this.range.controls.start.valueChanges.subscribe(s => {
			if (s) {
				this.range.controls.end.setValidators([Validators.required]);
			} else {
				this.range.controls.end.setValidators([]);
			}
			this.range.controls.end.updateValueAndValidity();
		});
	}


	initRange() {
		const today = new Date();
		const twoWeeks = new Date();
		this.range.controls.start.setValue(today);
		twoWeeks.setDate(twoWeeks.getDate() + 14);
		this.range.controls.end.setValue(twoWeeks);
	}

	getUnscheduled() {
		this.noRecords = false;
		const territory = this.terrService.getTerritory();
		this.myEventsSvc.getUnscheduledEvents(territory).subscribe(
			(response: MyEventsDetails[]) => {
				this.data = this.groupEvents(response);
				this.displayOwnedByRecords(this.formOwnedBy.value);
				this.allUnscheduledData = JSON.parse(JSON.stringify(this.data));
				if (this.data.length === 0) {
					this.noRecords = true;
				}
			},
			(error) => {
				if (error.status === 404) {
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				} else {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
				}

				this.showNotification = true;
			}
		);
	}

	getDataForRange() {
		this.ownedByMeCtrl.setValue(true);

		this.noRecords = false;
		const searchFilter = this.buildMyEventsFilter();
		const territory = this.terrService.getTerritory();
		this.myEventsSvc.getScheduledEvents(territory, searchFilter).subscribe(
			(response: MyEventsDetails[]) => {
				this.data = this.groupEvents(response);
				this.displayOwnedByRecords(this.formOwnedBy.value);
				if (this.data.length === 0) {
					this.noRecords = true;
				}
			},
			(error) => {
				if (error.status === 404) {
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				} else {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
				}

				this.showNotification = true;
			}
		);
	}

	buildMyEventsFilter(): MyEventsFilter {
		if (!this.range.controls.start.value || !this.range.controls.end.value) {
			return null;
		}

		const searchFilter: MyEventsFilter = new MyEventsFilter();

		// start date
		const startScheduledDate = new Date(this.range.controls.start.value);
		startScheduledDate.setHours(0);
		startScheduledDate.setMinutes(0);
		startScheduledDate.setSeconds(0);
		startScheduledDate.setMilliseconds(0);
		searchFilter.startScheduledDate = startScheduledDate;

		// end date
		const endScheduledDate = new Date(this.range.controls.end.value);
		endScheduledDate.setDate(endScheduledDate.getDate() + 1);
		endScheduledDate.setHours(0);
		endScheduledDate.setMinutes(0);
		endScheduledDate.setSeconds(0);
		endScheduledDate.setMilliseconds(0);
		searchFilter.endScheduledDate = endScheduledDate;

		return searchFilter;
	}

	groupEvents(data: MyEventsDetails[]): MyEvents[] {
		let newData = [];

		data.forEach(d => {
			const oldDate = new Date(d.scheduledDate);
			let newDate = oldDate;
			if (d.isAllDayEvent !== true) {
				newDate = UtilsService.dateToServerDate(oldDate);
			}

			newData.push({
				scheduleId: d.scheduleId,
				newDate: newDate,
				scheduleDate: this.scheduled ? this.datePipe.transform(newDate, 'MM/dd/yyyy') : 'Unscheduled Events',
				time: this.datePipe.transform(newDate, 'h:mm a'),
				isAllDayEvent: d.isAllDayEvent,
				type: d.type,
				merchandiserName: d.merchandiserName,
				accountName: d.accountName,
				appointmentName: d.appointmentName,
				subject: d.subject,
				address: d.address,
				zone: d.zone,
				route: d.route,
				chain: d.chain,
				str: d.str,
				shipTo: d.shipTo,
				lat: d.lat,
				lon: d.lon,
				isDisabled: d.isDisabled,
				completedStatus: d.completedStatus,
				ownedBy: d.type === MyEventType.OUTLOOK.valueOf() ? this.loginSvc._eid : d.ownedBy,
				ownedByName: d.type === MyEventType.OUTLOOK.valueOf() ? this.loginSvc._name : d.ownedByName,
				show: true
			});
		});

		newData = newData.sort((a, b) => {
			return <any>new Date(a.newDate) - <any>new Date(b.newDate);
		});

		// group by method
		return this.groupBy(newData, 'scheduleDate');
	}

	groupBy(collection, property) {
		let i = 0, val, index;
		const values = [], result: MyEvents[] = [];

		for (; i < collection.length; i++) {
			val = collection[i][property];
			index = values.indexOf(val);
			if (index > -1) {
				result[index].myEvents.push({
					scheduleId: collection[i].scheduleId,
					type: collection[i].type,
					time: collection[i].time,
					isAllDayEvent: collection[i].isAllDayEvent,
					merchandiserName: collection[i].merchandiserName,
					accountName: collection[i].accountName,
					appointmentName: collection[i].appointmentName,
					subject: collection[i].subject,
					address: collection[i].address,
					zone: collection[i].zone,
					route: collection[i].route,
					chain: collection[i].chain,
					str: collection[i].str,
					shipTo: collection[i].shipTo,
					lat: collection[i].lat,
					lon: collection[i].lon,
					isDisabled: collection[i].isDisabled,
					completedStatus: collection[i].completedStatus,
					ownedBy: collection[i].ownedBy,
					ownedByName: collection[i].ownedByName,
					show: true
				});
			} else {
				values.push(val);
				result.push({
					scheduleDate: collection[i].scheduleDate,
					show: true,
					myEvents: [{
						scheduleId: collection[i].scheduleId,
						type: collection[i].type,
						time: collection[i].time,
						isAllDayEvent: collection[i].isAllDayEvent,
						merchandiserName: collection[i].merchandiserName,
						accountName: collection[i].accountName,
						appointmentName: collection[i].appointmentName,
						subject: collection[i].subject,
						address: collection[i].address,
						zone: collection[i].zone,
						route: collection[i].route,
						chain: collection[i].chain,
						str: collection[i].str,
						shipTo: collection[i].shipTo,
						lat: collection[i].lat,
						lon: collection[i].lon,
						isDisabled: collection[i].isDisabled,
						completedStatus: collection[i].completedStatus,
						ownedBy: collection[i].ownedBy,
						ownedByName: collection[i].ownedByName,
						show: true
					}]
				});
			}
		}
		return result;
	}

	getUnscheduledCount() {
		this.displayUnscheduledBadge = false;
		const terr = this.territorySvc.getTerritory();
		this.myEventsSvc.getUnscheduledCount(terr).subscribe(
			response => {
				if (response.count > 0) {
					this.displayUnscheduledBadge = true;
				}
			}, error => {
				if (error.status === 404) {
					this.msgBanner.addMsgError(this.messageList, error.error.message);
				} else {
					this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator.');
				}

				this.showNotification = true;
			}
		);
	}

	viewMap(idx: number) {
		const coords = this.buildCoords(idx);
		this.dialog.open(ViewMapComponent, {
			data: coords,
			disableClose: true
		});
	}

	buildCoords(idx: number) {
		const coords = [];
		this.data[idx].myEvents.forEach(e => {
			if (e.lat != null && e.lon != null) {
				coords.push({
					lat: e.lat,
					lon: e.lon,
					label: e.accountName,
					chain: e.chain,
					str: e.str,
					shipTo: e.shipTo,
					address: e.address
				});
			}
		});
		return coords;
	}

	changePage() {
		this.data = [];
		this.scheduled = !this.scheduled;
		this.ownedByMeCtrl.setValue(true);
		if (this.scheduled) {
			this.getDataForRange();
		} else {
			this.getUnscheduled();
		}
	}

	checkLocation(idx: number) {
		let counter = 0;
		const max = this.data[idx].myEvents.length;
		this.data[idx].myEvents.forEach(e => {
			if (e.lat == null || e.lon == null) {
				counter++;
			}
		});

		return counter !== max;
	}

	isConflict(event: MyEventsArray, myEvents: MyEventsArray[]): boolean {
		let count = 0;

		const eid = this.loginSvc._eid.toLowerCase();
		const events = myEvents
			.filter(e => e.ownedBy.toLowerCase() === eid);

		events
			.forEach(e => {
				if (e.time === event.time && e.time !== null && e.ownedBy.toLowerCase() === event.ownedBy.toLowerCase()) {
					count++;
				}
			});
		return count > 1;
	}

	updateAllComplete() {
		this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.checked);
		this.refreshData();
		this.displayOwnedByRecords(this.ownedByMeCtrl.value);
	}

	someComplete(): boolean {
		if (this.task.subtasks == null) {
			return false;
		}
		return this.task.subtasks.filter(t => t.checked).length > 0 && !this.allComplete;
	}

	setAll(checked: boolean) {
		this.allComplete = checked;
		if (this.task.subtasks == null) {
			return;
		}
		this.task.subtasks.forEach(t => (t.checked = checked));
		this.refreshData();
	}

	refreshData() {
		if (this.allComplete) {
			this.data = JSON.parse(JSON.stringify(this.allUnscheduledData));
			return;
		}

		const data: MyEvents[] = JSON.parse(JSON.stringify(this.allUnscheduledData));
		data.forEach(dat => {
			const events = [];
			this.task.subtasks.forEach(s => {
				if (s.checked) {
					const filter = dat.myEvents.filter(e => e.type === s.id);
					filter.forEach(f => events.push(f));
				}
			});
			dat.myEvents = events;
		});
		if (this.atLeastOneChecked()) {
			this.data = data;
		} else {
			this.data = JSON.parse(JSON.stringify(this.allUnscheduledData));
		}
	}

	atLeastOneChecked() {
		let checked = false;
		this.task.subtasks.forEach(s => {
			if (s.checked) {
				checked = true;
			}
		});
		return checked;
	}

	buildScheduleDate(scheduleDate: string) {
		if (scheduleDate === 'Unscheduled Events') {
			return null;
		}

		const [month, day, year] = scheduleDate.split('/');
		return new Date(+year, +month - 1, +day);
	}

	onReloadAllEvents(event?: any) {
		this.loadData();
	}

	onRangeDateChange(value) {
		if (value && this.validateDateRange()) {
			this.loadData();
		}
	}

	validateDateRange() {
		return this.range.valid &&
			this.range.controls.start.value != undefined &&
			this.range.controls.start.value != null &&
			this.range.controls.end.value != undefined &&
			this.range.controls.end.value != null;
	}

	displayOwnedByRecords(v: boolean) {
		const eid = this.loginSvc?._eid?.toLowerCase();

		this.data.forEach(d => {
			d.myEvents.forEach(m => {
				if (v === true && m.ownedBy.toLowerCase() !== eid) {
					m.show = false;
				}
				if (v === false) {
					m.show = true;
				}
			});
		});

		this.data.forEach(d => {
			const notDisplayed = d.myEvents.filter(m => m.show === false);
			const notDisplayedCount = notDisplayed ? notDisplayed.length : 0;

			if (this.scheduled && d.show === false && notDisplayedCount !== d.myEvents.length) {
				d.show = true;
			}

			if (this.scheduled && notDisplayedCount === d.myEvents.length) {
				d.show = false;
			}
		});

		if (!this.scheduled && this.data[0]?.myEvents?.length !== 0) {
			this.allUnscheduledData = JSON.parse(JSON.stringify(this.data));
		}
	}

	rescheduleDayEvents(events: MyEvents) {
		const dialogRef = this.dialog.open(RescheduleDayDialogComponent, {
			data: {
				events: events
			},
			disableClose: true
		});

		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.onReloadAllEvents();
			}
		});
	}

	deleteDayEvents(events: MyEvents) {
		var availableEvents: MyEventsArray[] = this.eventsUtils.getActiveNonOutlookEvents(events);

		if (availableEvents?.length <= 0)
			return

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: `Are you sure you want to remove all events from ${events.scheduleDate}`,
				confirmationRequired: true,
				yesButtonShow: true,
				cancelButtonShow: true
			},
		});

		dialogRef.afterClosed().subscribe((response) => {
			if (response) {
				console.log('Delete the following events', availableEvents);
				this.trainingAuditService.deleteBulkTrainingAudit(availableEvents.map(event => event.scheduleId)).subscribe({
					next: (responseList: ScheduleResponse[]) => {
						this.displayRemoveAllEventConfirmation(events.scheduleDate, responseList);
					},
					error: () => {
						this.displayErrorDialog(`Events from ${events.scheduleDate} could not be removed. Please contact your administrator.`);
					}
				})
			}
		});
	}

	displayRemoveAllEventConfirmation(scheduleDate: string, responseList: ScheduleResponse[]) {
		const removedSuccessfullyFromOutlook: boolean = responseList.every(response => response.outlookEventSuccess);
		const successMessage = removedSuccessfullyFromOutlook
			? `All events from ${scheduleDate} have been successfully removed!`
			: `All the events from ${scheduleDate} have been successfully removed, but for some of them the Outlook Events failed to be deleted!`;

		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: successMessage,
				confirmationRequired: false
			},
		});

		dialogRef.afterClosed().subscribe(() => {
			this.onReloadAllEvents();
		});
	}

	displayErrorDialog(message: string) {
		this.dialog.open(ConfirmDialogComponent, {
			maxWidth: '400px',
			disableClose: true,
			data: {
				message: message,
				confirmationRequired: false
			},
		});
	}
}
