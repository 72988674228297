import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import {CountryGlobalVariables} from '../../shared/constants/country-global-variables';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return '../../....';
    }

    if (args) {
      return super.transform(value, CountryGlobalVariables.UK.datePipeFormat);
    }

    return super.transform(value, CountryGlobalVariables.US.datePipeFormat);
  }
}
